<template>
  <MenuLink v-bind="$attrs">
    <template v-slot:link>
      <div class="inline-flex align-middle has-text-primary">
        <SvgArrowLeft size="1.25rem" />
        <span
          class="ml-1"
          v-text="linkText"
        />
      </div>
    </template>
  </MenuLink>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    linkText: {
      type: String,
      required: true,
    },
  },
};
</script>
