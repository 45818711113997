<template>
  <li>
    <RouterLink
      :is="isI18nLink ? 'I18nRouterLink' : 'RouterLink'"
      v-bind="$attrs"
      exact-active-class="is-active"
      :active-class="exactActive ? null : 'is-active'"
      v-on="$listeners"
    >
      <slot name="link">
        <div class="inline-flex align-middle">
          <component
            :is="`Svg${svgIcon}`"
            v-if="svgIcon"
            size="1.25rem"
            stroke-width="1.75"
            class="mr-2"
            :class="svgIconClass"
          />
          <span v-text="linkText" />
        </div>
      </slot>
    </RouterLink>
    <slot name="default" />
  </li>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    linkText: {
      type: String,
      required: false,
      default: null,
    },
    svgIcon: {
      type: String,
      required: false,
      default: null,
    },
    svgIconClass: {
      type: [Object, Array, String],
      required: false,
      default: null,
    },
    exactActive: Boolean,
    isI18nLink: Boolean,
  },
};
</script>
